import React, { Component } from "react"
import styled from "styled-components"
import Input from "../../Atoms/Input"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import Button from "../../Atoms/Button"
import { Formik } from "formik"
import Axios from "axios"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0;
  }
`

const StyledContent = styled.div`
  flex: 1;
  padding-right: 110px;

  @media screen and (max-width: 900px) {
    padding-right: 0;
  }
`

const StyledSubHeader = styled.span`
  color: ${GlobalColors.gray500};
  font-family: Teko;
  display: block;
  text-transform: uppercase;
  margin-top: 12px;
`

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 2.4rem;
  height: auto;
`

const StyledInfo = styled.p`
  color: ${GlobalColors.gray500};
  margin: 24px 0 40px;

  input {
    margin-bottom: 20px;
  }
`

const StyledForm = styled.form`
  flex: 1;
  max-width: 458px;

  @media screen and (max-width: 900px) {
    margin-top: 60px;
  }
`

const StyledSpinner = styled.div`
  display: inline-block;
  position: relative;
width: 25px;
    height: 25px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    margin: 0px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StyledEmailWrapper = styled.div`
  border-left: 2px solid ${GlobalColors.gray300};
  margin-top: 80px;
  padding-left: 28px;
  width: 280px;

  p {
    color: ${GlobalColors.gray500};
  }
  a {
    color: ${GlobalColors.primary};
    font-weight: 700;
    text-decoration: none;
  }
`

class ContactForm extends Component {

  state = {
    isSubmitted: false,
    error: null,
  }

  render() {
    const { children, emailTo = 'biuro@mywash.pl' } = this.props
    return (
      <StyledWrapper className="pb16 mt16">
        {children ? (
          <StyledContent>{children}</StyledContent>
        ) : (
          <StyledContent className="mb8">
            <h2 className="header2 no-margin">{localize("192")}</h2>
            <StyledSubHeader className="header4">
              {localize("193")}
            </StyledSubHeader>
            <StyledEmailWrapper>
              <p className="body-small">
                {localize("194")}
              </p>
              <a href={`mailto:${emailTo}`}>{emailTo}</a>
            </StyledEmailWrapper>
          </StyledContent>
        )}
        <Formik
          initialValues={{ name: "", city: "", email: "", phone: "" }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = "Required"
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address"
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            Axios.post("https://mywash.pl/email", { emailTo, ...values })
              .then(res => {
                this.setState({ isSubmitted: true })
                setSubmitting(false)
              })
              .catch(e => {
                this.setState({ error: localize("195") + "biuro@mywash.pl" })
                setSubmitting(false)
              })
          }}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
            <StyledForm onSubmit={handleSubmit} className="mobile-container">
              <Input
                label={localize("196")}
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required
              />
              <Input
                label={localize("197")}
                type="text"
                name="city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
              />
              <Input
                label={localize("198")}
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required
              />
              <Input
                label={localize("199")}
                type="tel"
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
              <StyledInfo className="body-small">
                * {localize("200")}
              </StyledInfo>
              <StyledButton type="submit" disabled={isSubmitting || this.state.isSubmitted}>
                {isSubmitting ? (
                  <>
                    <StyledSpinner>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </StyledSpinner>
                  </>
                ) : (
                  this.state.isSubmitted ? localize("201") :
                    localize("202")
                )}
              </StyledButton>
              <p>{this.state.error ? this.state.error : ""}</p>
            </StyledForm>
          )}
        </Formik>
      </StyledWrapper>
    )
  }
}

export default ContactForm
