import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../assets/styles/GlobalVariables"

const StyledInput = styled.input`
  padding: 16px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-radius: 4px;
  border: 1px solid #DAE2E6;
  display: block;
  margin-bottom: 24px;
  width: 100%;
  max-width: 458px;
  background-color: ${({isRevert}) => isRevert ? GlobalColors.black : GlobalColors.white};
  color: inherit;
`

const StyledLabel = styled.label`
  color: ${({isRevert}) => isRevert ? GlobalColors.white : GlobalColors.black};
`

const Input = ({ type, label, isRevert, className, ...props }) => (
  <div className={className}>
    <StyledLabel isRevert={isRevert}>{label}</StyledLabel>
    <StyledInput isRevert={isRevert} type={type} {...props}/>
  </div>
)

export default Input
